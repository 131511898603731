import React, { ChangeEvent } from 'react';
import { EditorExtensionSDK } from '../../../extensions-sdk';
import {
  FormLabel,
  Switch,
  Paragraph,
  Checkbox,
  Tooltip,
  FormControl,
  Flex,
} from '@contentful/f36-components';
import { HelpCircleIcon } from '@contentful/f36-icons';
import { isFieldReference } from '../../../utils/contentful';
import { getContentTypes, removeValueAndChildren } from '../../../utils/helpers';

interface Props {
  sdk: EditorExtensionSDK;
  localizedReferences: boolean;
  selectedReferenceFields: string[];
  handleFieldChange: Function;
  showLinkedReference: boolean;
}

interface State {
  contentTypes: any[];
  localizedReferenceFields: any[];
}

export default class LocalizedReferenceField extends React.Component<Props, State> {
  detachValueChangeHandler = () => {};
  state: State = {
    contentTypes: [],
    localizedReferenceFields: [],
  };

  async componentDidMount() {
    this.detachValueChangeHandler = this.props.sdk.entry.fields['contentType'].onValueChanged(
      this.handleContentTypeChange,
    );
    await this.handleMount();
  }

  componentWillUnmount() {
    this.detachValueChangeHandler();
  }

  handleMount = async () => {
    const contentTypes = await getContentTypes(this.props.sdk);
    this.setState({
      contentTypes: contentTypes || [],
    });
    await this.handleContentTypeChange();
  };

  handleContentTypeChange = async () => {
    const contentType = this.props.sdk.entry.fields.contentType.getValue();
    if (contentType) {
      const contentTypeObject: any = this.state.contentTypes.find((cType: any) => {
        return cType.sys.id === contentType;
      });
      if (contentTypeObject && contentTypeObject.fields) {
        const localizedReferenceFields = contentTypeObject.fields
          .filter((field: any) => {
            return isFieldReference(field);
          })
          .sort((a: any, b: any) => a.id.localeCompare(b.id));
        this.setState(
          {
            localizedReferenceFields,
          },
          () => {
            // if stored field had linked references but now the content type doesn't have localized field make it false
            if (this.props.localizedReferences && localizedReferenceFields.length == 0) {
              this.props.handleFieldChange('localizedReferences');
            }
          },
        );
      }
    }
  };
  updateSelected = async (value: string) => {
    let selectedReferenceFields = [...this.props.selectedReferenceFields]; // Make a copy to avoid mutating props directly
    if (selectedReferenceFields.includes(value)) {
      let linkedContentTypes = await this.props.sdk.entry.fields['linkedContentTypes'].getValue();
      await removeValueAndChildren(value, selectedReferenceFields, linkedContentTypes);
    } else {
      selectedReferenceFields.push(value);
    }
    this.props.handleFieldChange('selectedReferenceFields', selectedReferenceFields);
  };

  toggleField = async (field: 'localizedReferences' | 'showLinkedReference') => {
    const value = !this.props[field];
    this.props.handleFieldChange(field, value);
  };

  render() {
    return (
      <>
        {this.state.localizedReferenceFields.length > 0 && (
          <FormControl>
            <FormLabel htmlFor="localizedReference">{'Linked references'}</FormLabel>
            <Flex justifyContent="space-between">
              <FormControl style={{ display: 'inline-flex' }} className="no-margin">
                <Switch
                  id={'pro-lo-ref'}
                  isChecked={this.props.localizedReferences}
                  onChange={async () => {
                    await this.toggleField('localizedReferences');
                  }}
                />{' '}
                <FormControl.Label style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                  Process linked references
                </FormControl.Label>
                <Tooltip
                  content={
                    'Enabling this will include content for linked source Entries in the fields selected below.'
                  }
                >
                  <HelpCircleIcon className={'tooltip-icon'} variant={'muted'} />
                </Tooltip>
              </FormControl>
              {this.props.localizedReferences && (
                <Flex>
                  <Switch
                    id={'pro-lo-ref-1'}
                    isChecked={this.props.showLinkedReference}
                    onChange={async () => {
                      await this.toggleField('showLinkedReference');
                    }}
                  />
                  <FormControl.Label style={{ paddingRight: '5px', paddingLeft: '10px' }}>
                    Show linked references
                  </FormControl.Label>
                  <Tooltip
                    content={
                      'Enabling this will also show linked referenced entries in the source content listing below leading to a longer load time.'
                    }
                  >
                    <HelpCircleIcon className={'tooltip-icon'} variant={'muted'} />
                  </Tooltip>
                </Flex>
              )}
            </Flex>
            {this.props.localizedReferences && (
              <Flex className="localised-references-container">
                <Paragraph>Select linked reference fields the app should translate</Paragraph>
                <Flex
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                  }}
                >
                  {this.state.localizedReferenceFields.map((field: any) => {
                    return (
                      <span key={field.id} style={{ margin: '0.35rem 0' }}>
                        <Checkbox
                          value={field.id}
                          isChecked={this.props.selectedReferenceFields.includes(field.id)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            this.updateSelected(e.target.value)
                          }
                          id={field.id}
                        >
                          {field.name}
                        </Checkbox>
                        &nbsp;
                      </span>
                    );
                  })}
                </Flex>
              </Flex>
            )}
          </FormControl>
        )}
      </>
    );
  }
}
